<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const touchStartX = ref(0);
const touchEndX = ref(0);
const swipeDirection = ref('');

const handleTouchStart = (event) => {
  touchStartX.value = event.changedTouches[0].clientX;
};

const handleTouchEnd = (event) => {
  touchEndX.value = event.changedTouches[0].clientX;
  const swipeThreshold = 50;

  if (touchStartX.value - touchEndX.value > swipeThreshold) {
    swipeDirection.value = 'next';
    setTimeout(() => {
      router.push('/thirdTawaf');
    }, 300);
  } else if (touchEndX.value - touchStartX.value > swipeThreshold) {
    swipeDirection.value = 'prev';
    setTimeout(() => {
      router.push('/tawaf');
    }, 300);
  }
};
</script>

<template>
  <section class="background" @touchstart="handleTouchStart" @touchend="handleTouchEnd">
    <div class="container">
      <div class="header_block">
        <router-link to="">
          <img src="@/assets/img/icons/prev.svg">
        </router-link>
        <h2 class="title">Таваф</h2>
        <div></div>
      </div>

        <div class="content">
          <div class="info">
            <p class="text">8. При совершении тавафа необходимо приложить максимум усилий, чтобы не навредить окружающим.</p>

            <p class="text">Как было сказано выше, совершающий умру должен произносить слова тальбии, начиная с миката до тех пор, пока не увидит Каабу. При прибытии паломника в благословенную мекку, желательным для него является совершение полного омовения. Затем он отправляется к Священной мечети, чтобы выполнить обряды умры. Совершение полного омовения перед отправлением в мечеть не обязательно.<br><br>
              Входить в мечеть следует правой ногой, произнося следующие слова:</p>
            <p class="text text_arab">أَعُوذُ بِاللَّهِ العَظِيمِ، وَبِوَجْهِهِ الْكَرِيمِ، وَسُلْطَانِهِ الْقَدِيمِ، مِنَ <br>الشَّيْطَانِ الرَّجِيمِ</p>
            <p class="text center">«А‘узу би-Лляхи ль-‘Азыми ва би-ваджхи-хи ль-карими ва сультани-хи ль-кадими мин аш-шайтани р-раджим»</p>

            <p class="text text_orange center">«Прибегаю к защите Аллаха Великого, Его благородного лика и Его предвечной <br>власти от проклятого шайтана»</p>
          </div>
          <div class="pagination">
            <span >{{ 2 }}/{{ 5 }}</span>
          </div>
        </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.content {
  position: relative;
}
.info {
  display: flex;
  flex-direction: column;
  align-items: center;

  .text {
    &:not(:first-child) {
      padding-top: 20px;
    }
  }
  .center {
    text-align: center;
  }
}
.pagination {
  position: absolute;
  bottom: 27px;
  left: 50%;
  transform: translateX(-50%);
  color: #525D68;
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
