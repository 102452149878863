<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const touchStartX = ref(0);
const touchEndX = ref(0);

const handleTouchStart = (event) => {
  touchStartX.value = event.changedTouches[0].clientX;
};

const handleTouchEnd = (event) => {
  touchEndX.value = event.changedTouches[0].clientX;
  const swipeThreshold = 50;

  if (touchEndX.value - touchStartX.value > swipeThreshold) {
    setTimeout(() => {
      router.push('/secondSai');
    }, 300);
  }
};
</script>

<template>
  <section class="background" @touchstart="handleTouchStart" @touchend="handleTouchEnd">
    <div class="container">
      <div class="header_block">
        <router-link to="">
          <img src="@/assets/img/icons/prev.svg">
        </router-link>
        <h2 class="title">Са’й</h2>
        <div></div>
      </div>

        <div class="content">
          <div class="info">
            <p class="text"><span class="text_orange">2. Марва.</span> Достигнув Марвы, паломник поворачивается к каабе и произносит те же слова, что и на сафе, за исключением аята. Делает ду’а, о чем пожелает и направляетсяк Сафе. Всякий раз, когда мужчины подходят к зеленым фонарям, им необходимо ускорять свой шаг до следующих фонарей. На сафе повторяются те же действия. Прохождение расстояния от Сафы до Марвы засчитывается за один круг са’я. В такой вот форме следует совершить семь кругов са’я.</p>
            <p class="text">Паломнику, начавшему са’й пешим ходом, разрешается закончить его на коляске, если он почувствует физическое недомогание или боль, не позволяющую продолжать движение самостоятельно.</p>
            <p class="text">После са’йя совершающий умру сбривает или укорачивает волосы на голове (бритье волос предпочтительней). Волосы должны быть равномерно подстриженными по всей голове.</p>
          </div>
          <div class="pagination">
            <span>{{ 3 }}/{{ 3 }}</span>
          </div>
        </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.content {
  position: relative;
}
.info {
  .text:not(:first-child) {
    padding-top: 16px;
  }
}
.pagination {
  position: absolute;
  bottom: 27px;
  left: 50%;
  transform: translateX(-50%);
  color: #525D68;
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
