<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const touchStartX = ref(0);
const touchEndX = ref(0);
const swipeDirection = ref('');

const handleTouchStart = (event) => {
  touchStartX.value = event.changedTouches[0].clientX;
};

const handleTouchEnd = (event) => {
  touchEndX.value = event.changedTouches[0].clientX;
  const swipeThreshold = 50;

  if (touchStartX.value - touchEndX.value > swipeThreshold) {
    swipeDirection.value = 'next';
    setTimeout(() => {
      router.push('/fourthIhram');
    }, 300);
  } else if (touchEndX.value - touchStartX.value > swipeThreshold) {
    swipeDirection.value = 'prev';
    setTimeout(() => {
      router.push('/secondIhram');
    }, 300);
  }
};
</script>

<template>
  <section class="background" @touchstart="handleTouchStart" @touchend="handleTouchEnd">
    <div class="container">
      <div class="header_block">
        <router-link to="">
          <img src="@/assets/img/icons/prev.svg">
        </router-link>
        <h2 class="title">Ихрам</h2>
        <div></div>
      </div>
      <div class="content">
        <div class="info__text">
          <i class="text_green">Посланник Аллаха сказал: «Нельзя надевать (будучи в ихраме) рубашку, чалму, брюки, любую одежду, покрывающую тело вместе с головой, и обувь, целиком покрывающую ноги. Если же паломник не найдет сандалий, то пусть наденет кожаную обувь, предварительно обрезав ее ниже щиколоток. Не надевайте также одежды, окрашенные шафраном или желтым деревом».</i>
          <p class="text__source">(Аль-Бухари и Муслим)</p>
        </div>
        <div class="pagination">
          <span >{{ 3 }}/{{ 8 }}</span>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.content {
  position: relative;
}
info__text {
  padding-top: 16px;
}
.pagination {
  position: absolute;
  bottom: 27px;
  left: 50%;
  transform: translateX(-50%);
  color: #525D68;
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
