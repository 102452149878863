<script setup>
import {ref} from "vue";

const currentPage = ref(1);
const totalPages = ref(2);

let startX = 0;
let startY = 0;

function onTouchStart(event) {
  startX = event.touches[0].clientX;
  startY = event.touches[0].clientY;
}

function onTouchMove(event) {
  let deltaX = event.touches[0].clientX - startX;
  let deltaY = event.touches[0].clientY - startY;
  if (Math.abs(deltaY) < Math.abs(deltaX)) {
    event.preventDefault();
  }
}

function onTouchEnd(event) {
  let deltaX = event.changedTouches[0].clientX - startX;
  if (deltaX > 50) {
    goToPreviousPage();
  } else if (deltaX < -50) {
    goToNextPage();
  }
}

function goToPreviousPage() {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
}

function goToNextPage() {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
}
</script>

<template>
  <section class="dua" @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd">
   <div class="container dua__container">
     <div class="header-block">
       <router-link to="/dua">
         <img src="@/assets/img/icons/prev.svg">
       </router-link>
     </div>

    <div class="dua__content">
      <div class="dua__block">
        <div class="dua_box" v-if="currentPage === 1">
          <p class="title_pre">НАМЕРЕНИЕ НА ИХРАМ</p>
          <p class="text text_arab">اللهم لبيك عمرة</p>
          <p class="text">«Аллахумма ляббейка умратан»</p>
          <p class="text text_orange">«Вот я перед тобой, (о Аллах),<br>
            совершаю малый хадж»</p>
        </div>

        <div class="dua_box" v-if="currentPage === 2">
          <p class="title_pre">ТАЛЬБИЯ</p>
          <p class="text text_arab">لبَّيكَ اللهمَّ لبَّيكَ، لبَّيكَ لا شريكَ لك لبَّيكَ؛ <br>إنَّ الحمدَ والنِّعمةَ لك والمُلْك، لا شريكَ لك</p>
          <p class="text">«Ляббайка-ллахумма ляббайк,<br>  ляббайка ля шарика ляка ляббайк,<br>  инналь-хамда, ванниъмата ляка валь- <br> мулк, ля шарика ляк».</p>
          <p class="text text_orange">«Вот я перед Тобой, о Аллах, вот я перед <br> Тобой! Нет у тебя сотоварища, вот я перед <br> тобой! Поистине вся хвала, милость и <br> владычество принадлежат Тебе, нет у Тебя <br> сотоварищей».</p>
        </div>

        <span class="pagination">{{ currentPage }}/{{ totalPages }}</span>

      </div>
    </div>
   </div>
  </section>
</template>

<style lang="scss" scoped>
.dua {
  &__container {
    height: 100%;
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  &__block {
    position: relative;
    background-color: white;
    border-radius: 20px;
    padding: 60px 0 30px 0;
    text-align: center;
    height: 355px;
    width: 100%;
  }
  .text {
    text-align: center;
    padding-top: 24px;
  }
  .pagination {
    position: absolute;
    bottom: 0;
    padding-bottom: 30px;
    transform: translateX(-50%);
    color: #525D68;
  }
}
</style>
