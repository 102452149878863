<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const touchStartX = ref(0);
const touchEndX = ref(0);

const handleTouchStart = (event) => {
  touchStartX.value = event.changedTouches[0].clientX;
};

const handleTouchEnd = (event) => {
  touchEndX.value = event.changedTouches[0].clientX;
  const swipeThreshold = 50;

  if (touchEndX.value - touchStartX.value > swipeThreshold) {
    setTimeout(() => {
      router.push('/seventhIhram');
    }, 300);
  }
};
</script>

<template>
  <section class="background" @touchstart="handleTouchStart" @touchend="handleTouchEnd">
    <div class="container">
      <div class="header_block">
        <router-link to="">
          <img src="@/assets/img/icons/prev.svg">
        </router-link>
        <h2 class="title">Ихрам</h2>
        <div></div>
      </div>

      <div class="content">
        <h2 class="title_pre">ВО ВРЕМЯ ИХРАМА РАЗРЕШАЮТСЯ СЛЕДУЮЩИЕ ВЕЩИ</h2>

        <div class="info">
          <div class="info__block">
            <img src="">
            <p class="text">1. надевать часы</p>
          </div>
          <div class="info__block">
            <img src="">
            <p class="text">2. использовать слуховые аппараты или наушники</p>
          </div>
          <div class="info__block">
            <img src="">
            <p class="text">3. носить кольца</p>
          </div>
          <div class="info__block">
            <img src="">
            <p class="text">4. надевать сандалии</p>
          </div>
          <div class="info__block">
            <img src="">
            <p class="text">5. носить очки.</p>
          </div>
          <div class="info__block">
            <img src="">
            <p class="text">6. Подпоясываться ремнем.</p>
          </div>
          <div class="info__block">
            <img src="">
            <p class="text">7. укрываться под тенью зонта</p>
          </div>
          <div class="info__block">
            <img src="">
            <p class="text">8. укрываться под крышей машины</p>
          </div>
          <div class="info__block">
            <img src="">
            <p class="text">9. носить поклажу на голове</p>
          </div>
          <div class="info__block">
            <img src="">
            <p class="text">10. носить постель на голов</p>
          </div>
          <div class="info__block">
            <img src="">
            <p class="text">11. Перевязывать бинтом рану, менять одежду
              ихрама и стирать ее</p>
          </div>
          <div class="info__block">
            <img src="">
            <p class="text">12. мыть голову и принимать душ, если при этом случайно выпадут волосы, то в этом нет ничего греховного</p>
          </div>
        </div>
        <div class="pagination">
          <span>{{ 8 }}/{{ 8 }}</span>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.content {
  position: relative;
}
.info {
  padding-block: 24px;
  &__block {
    display: flex;
    &:not(:first-child) {
      padding-top: 16px;
    }
  }
}

.pagination {
  position: absolute;
  bottom: 27px;
  left: 50%;
  transform: translateX(-50%);
  color: #525D68;
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
