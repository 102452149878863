<script>
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker, LPolyline } from "vue3-leaflet";
import iconUrlA from '../../assets/img/icons/location (5).png';
import iconUrlB from '../../assets/img/icons/location (6).png';

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPolyline
  },
  data() {
    return {
      zoom: 13,
      center: [21.4224779, 39.8251832], // Центр карты
      markers: [], // Массив для динамических маркеров
    };
  },
  methods: {
    addMarker(latitude, longitude, iconURL) {
      this.markers.push({
        latLng: [latitude, longitude],
        iconOptions: { iconUrl: iconURL, iconAnchor: [16, 33] } // Создаем объект с iconUrl
      });
    },

    fetchLocationUser() {
      const $latitude = 21.4224779; // Например, получено с бэкенда
      const $longitude = 39.8251832; // Например, получено с бэкенда

      this.addMarker($latitude, $longitude, iconUrlB);
    },
    fetchMyLocation() {
      const $latitude = 21.438973; // Например, получено с бэкенда
      const $longitude = 39.832638; // Например, получено с бэкенда

      this.addMarker($latitude, $longitude, iconUrlA);
    }
  },
  mounted() {
    this.fetchLocationUser();
    this.fetchMyLocation();
  },
};
</script>

<template>
  <div style="height:720px; width:800px">
    <l-map ref="map" v-model:zoom="zoom" :center="center">
      <l-tile-layer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          layer-type="base"
          name="OpenStreetMap"
      ></l-tile-layer>

      <l-marker v-for="(marker, index) in markers" :key="index" :lat-lng="marker.latLng" :iconOptions="marker.iconOptions"></l-marker>

      <l-polyline
          :latlngs="[
          [21.438973, 39.832638],
          [21.4224779, 39.8251832],
        ]"
          :color="'green'"
          :weight="3"
      ></l-polyline>
    </l-map>
  </div>
</template>

<style>
</style>
