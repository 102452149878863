<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const touchStartX = ref(0);
const touchEndX = ref(0);

const handleTouchStart = (event) => {
  touchStartX.value = event.changedTouches[0].clientX;
};

const handleTouchEnd = (event) => {
  touchEndX.value = event.changedTouches[0].clientX;
  const swipeThreshold = 50;

  if (touchStartX.value - touchEndX.value > swipeThreshold) {
    setTimeout(() => {
      router.push('/secondMikat');
    }, 300);
  }
};

const goBack = () => {
  if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.callbackHandler) {
    window.webkit.messageHandlers.callbackHandler.postMessage({
      action: "goBack",
      message: "User pressed the back button",
    });
  } else {
    router.back();
  }
};
</script>

<template>
  <section class="background" @touchstart="handleTouchStart" @touchend="handleTouchEnd">
    <div class="container">
      <div class="header_block">
        <button class="transparent-button" @click="goBack">
          <img src="@/assets/img/icons/prev.svg" alt="Back" />
        </button>
        <h2 class="title">Микаты</h2>
        <div></div>
      </div>
        <div class="content">
          <p class="text"><span class="text__selected">Микаты</span> – это стоянки, на которых паломники обла-
            чаются в ихрам. Пророк определил 5 микатов, на
            которых совершающие хадж или умру, обязаны вступить
            в состояние ихрама.</p>

          <div class="steps"></div>

          <div class="info__container">
            <p class="text"><span class="text__selected">1. Зуль-Хулейфа ذو الحليفة</span> - микат жителей Медины и
              паломников, прибывающих с этого направления.
              Сейчас это место называется «Абйар» и находится в 450
              км от Благосло- венной Мекки.</p>
            <p class="text"><span class="text__selected">2. Аль-Джухфа الجحفة</span> - микат жителей Шама (Сирии,
              Иордании, Палестины, Ливана), Марокко, Египта и
              паломников, прибывающих с этого направления. Находится вблизи горо- да Рабиг, в 183 км от Благословенной
              Мекки.</p>
          </div>
          <div class="pagination">
            <span>{{ 1 }}/{{ 3 }}</span>
          </div>
        </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.content {
  position: relative;
}
.steps {
  margin: 0 auto;
  background: url("../../assets/img/kaaba.svg") center/cover no-repeat;
  width: 100%;
  max-width: 420px;
  height: 190px;
  margin-top: 32px;
}
.info {
  &__container {
    padding-top: 32px;

    & .text:not(:first-child) {
      padding-top: 16px;
    }
  }
}

.left {
  text-align: left;
}
.center {
  text-align: center !important;
}

.pagination {
  position: absolute;
  bottom: 27px;
  left: 50%;
  transform: translateX(-50%);
  color: #525D68;
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
