<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const touchStartX = ref(0);
const touchEndX = ref(0);
const swipeDirection = ref('');

const handleTouchStart = (event) => {
  touchStartX.value = event.changedTouches[0].clientX;
};

const handleTouchEnd = (event) => {
  touchEndX.value = event.changedTouches[0].clientX;
  const swipeThreshold = 50;

  if (touchStartX.value - touchEndX.value > swipeThreshold) {
    swipeDirection.value = 'next';
    setTimeout(() => {
      router.push('/fifthIhram');
    }, 300);
  } else if (touchEndX.value - touchStartX.value > swipeThreshold) {
    swipeDirection.value = 'prev';
    setTimeout(() => {
      router.push('/thirdIhram');
    }, 300);
  }
};
</script>


<template>
  <section class="background" @touchstart="handleTouchStart" @touchend="handleTouchEnd">
    <div class="container">
      <div class="header_block">
        <router-link to="">
          <img src="@/assets/img/icons/prev.svg">
        </router-link>
        <h2 class="title">Ихрам</h2>
        <div></div>
      </div>

      <div class="content">
        <div class="topic">
          <h2 class="title_pre">НАМЕРЕНИЕ НА ИХРАМ</h2>
          <p class="text text_block text_arab">اللهم لبيك عمرة</p>
          <p class="text">«Аллахумма ляббейка умратан»</p>
        </div>

        <div class="topic_translate">
          <p class="text_orange center">«Вот я перед тобой, (о Аллах),<br> совершаю малый хадж»</p>
        </div>

        <div class="topic text_top">
          <h2 class="title_pre">ТАЛЬБИЯ</h2>
          <p class="text text_block text_arab">لبَّيكَ اللهمَّ لبَّيكَ، لبَّيكَ لا شريكَ لك لبَّيكَ؛ إنَّ<br> الحمدَ والنِّعمةَ لك والمُلْك، لا شريكَ لك</p>
          <p class="text">«Ляббайка-ллахумма ляббайк, ляббайка ля шарика ляка ляббайк, инналь-хамда, ванниъмата ляка вальмулк, ля шарика ляк».</p>
        </div>

        <div class="topic_translate">
          <p class="text_orange center">«Вот я перед Тобой, о Аллах,<br> вот я перед Тобой! Нет у тебя сотоварища, <br>вот я перед тобой! Поистине вся хвала,<br> милость и владычество принадлежат<br> Тебе, нет у Тебя сотоварищей».</p>
        </div>

        <div class="topic text_top">
          <h2 class="title_pre">ВРЕМЯ ТАЛЬБИИ ДЛЯ УМРЫ</h2>
          <p class="text text_block">С момента вхождения в ихрам и<br> до начала тавафа</p>
        </div>
        <div class="pagination">
          <span >{{ 4 }}/{{ 8 }}</span>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.content {
  position: relative;
}
.topic {
  display: flex;
  flex-direction: column;
  align-items: center;
  &_translate {
    padding-block: 16px;
  }
}
.text {
  text-align: center;
  &_block {
    padding-block: 16px;
  }
  &_top {
    padding-top: 16px;
  }
}

.center {
  text-align: center;
}
.pagination {
  position: absolute;
  bottom: 27px;
  left: 50%;
  transform: translateX(-50%);
  color: #525D68;
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
