<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const touchStartX = ref(0);
const touchEndX = ref(0);

const handleTouchStart = (event) => {
  touchStartX.value = event.changedTouches[0].clientX;
};

const handleTouchEnd = (event) => {
  touchEndX.value = event.changedTouches[0].clientX;
  const swipeThreshold = 50;

  if (touchEndX.value - touchStartX.value > swipeThreshold) {
    setTimeout(() => {
      router.push('/fourthTawaf');
    }, 300);
  }
};
</script>

<template>
  <section class="background" @touchstart="handleTouchStart" @touchend="handleTouchEnd">
    <div class="container">
      <div class="header_block">
        <router-link to="">
          <img src="@/assets/img/icons/prev.svg">
        </router-link>
        <h2 class="title">Таваф</h2>
        <div></div>
      </div>

      <div class="content">
        <div class="info">
          <h2 class="title_pre">СУРА «АЛЬ-КАФИРУН»</h2>
          <p class="text text_center">Бисмилләһир-рахмәәнир-рахиим</p>
          <p class="text text_center">Қул йаә әйюһәл кәәфируун (1) Ләәә әъбуду мәә тәъбу- дун (2) Уә ләә әнтум ъабидуунә мәә әъбуд (3) Уә ләә әнә ъаабидум мәә ъабәдтум (4) Уә ләә әнтум ъаабидуу- нә мәә әъбуд (5) Ләкум диинукум уә лиә диин (6)</p>
        </div>
        <div class="info">
          <h2 class="title_pre">СУРА «АЛЬ-ИХЛАС»</h2>
          <p class="text text_center">Бисмилләһир-рахмәәнир-рахиим</p>
          <p class="text text_center">Қул һуаллааһу ахаді (1) Аллаһус-сомәді (2) Ләм йәлид уә ләм йууләді (3), уә ләм йәкул-ләһуу куфууән ахаді (4)</p>

          <p class="text">3. После намаза паломнику следует попить воду замзам.</p>
        </div>
        <div class="pagination">
          <span>{{ 5 }}/{{ 5 }}</span>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.content {
  position: relative;
}
.info {
  &:not(:last-child) {
    padding-bottom: 24px;
  }
  .text {
    &:not(:first-child) {
      padding-top: 16px;
    }
    &_center {
      text-align: center;
    }
  }
}
.pagination {
  position: absolute;
  bottom: 27px;
  left: 50%;
  transform: translateX(-50%);
  color: #525D68;
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
