<script setup>
import { ref } from 'vue';

const currentPage = ref(1);
const totalPages = ref(2);

let startX = 0;
let startY = 0;

function onTouchStart(event) {
  startX = event.touches[0].clientX;
  startY = event.touches[0].clientY;
}

function onTouchMove(event) {
  let deltaX = event.touches[0].clientX - startX;
  let deltaY = event.touches[0].clientY - startY;
  if (Math.abs(deltaY) < Math.abs(deltaX)) {
    event.preventDefault();
  }
}

function onTouchEnd(event) {
  let deltaX = event.changedTouches[0].clientX - startX;
  if (deltaX > 50) {
    goToPreviousPage();
  } else if (deltaX < -50) {
    goToNextPage();
  }
}

function goToPreviousPage() {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
}

function goToNextPage() {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
}
</script>


<template>
  <section class="dua" @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd">
    <div class="container dua__container">
      <div class="header-block">
        <router-link to="/dua">
          <img src="@/assets/img/icons/prev.svg">
        </router-link>
      </div>

     <div class="dua__content">
       <div class="dua__block">
         <div class="dua_box" v-if="currentPage === 1">
           <p class="title_pre">СА’Й</p>
           <p class="text text_arab">إِنَّ ٱلصَّفَا وَٱلْمَرْوَةَ مِن شَعَآئِرِ ٱللَّهِۖ فَمَنْ حَجَّ ٱلْبَيْتَ <br>أَوِ ٱعْتَمَرَ فَلَا جُنَاحَ عَلَيْهِ أَن يَطَّوَّفَ بِهِمَاۚ وَمَن تَطَوَّعَ <br>خَيْرًا فَإِنَّ ٱللَّهَ شَاكِرٌ عَلِيمٌ</p>
           <p class="text">«Иннәс-сафа уаль-маруата мин шаъайи-<br> риллях. Фаман хаджалл-байта ауиътама-ра<br> фаля жунаха ъалайхи аййаттаууафа бихима.<br> Уа ман татаууаъа хайран фа-инналлаха<br> шакирун ъалим».</p>
           <p class="text text_orange">Затем он поднимается на сафу, становится<br> на ней и направляется лицом к каабе,<br> трижды восхваляет аллаха (альхамду лил-<br>лях) и возвеличивает его (аллаху акбар), и<br> подняв руки, делает ду’а (взывает):</p>
         </div>

         <div class="dua_box" v-if="currentPage === 2">
           <p class="title_pre">СА’Й</p>
           <p class="text text_arab">لا إله إلا الله، وحده لا شريك له، له الملك،<br> وله الحمد، يحيي ويميت وهو على كل شيء<br> قدير، لا إله إلا الله وحده أنجز وعده، ونصر <br>عبده، وهزم الأحزاب وحده</p>
           <p class="text">«Ля иляха илляллаху уахдаху ля<br> шарикалях. Ляхуль мульку уа ляхуль<br> хамду уа хууа ъаля кули шай ин кадир.<br> Ля иляха илляллаху уахдаху анджаза<br> уаъдаху уа насора ъабдаху уа<br> хазамаль ахзаба уахдаху».</p>
         </div>

         <span class="pagination">{{ currentPage }}/{{ totalPages }}</span>
       </div>
     </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.dua {
  &__container {
    height: 100%;
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  &__block {
    position: relative;
    background-color: white;
    border-radius: 20px;
    padding: 60px 0 30px 0;
    text-align: center;
    height: 355px;
    width: 100%;
  }
  .text {
    text-align: center;
    padding-top: 16px;
    &-top {
      padding-top: 24px;
    }
  }
  .texts {
    color: #525D68;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    text-align: justify;
    text-align: center;
    padding-top: 16px;
  }
  .pagination {
    position: absolute;
    bottom: 0;
    padding-bottom: 30px;
    transform: translateX(-50%);
    color: #525D68;
  }
}
</style>
