<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const touchStartX = ref(0);
const touchEndX = ref(0);
const swipeDirection = ref('');

const handleTouchStart = (event) => {
  touchStartX.value = event.changedTouches[0].clientX;
};

const handleTouchEnd = (event) => {
  touchEndX.value = event.changedTouches[0].clientX;
  const swipeThreshold = 50;

  if (touchStartX.value - touchEndX.value > swipeThreshold) {
    swipeDirection.value = 'next';
    setTimeout(() => {
      router.push('/thirdIhram');
    }, 300);
  } else if (touchEndX.value - touchStartX.value > swipeThreshold) {
    swipeDirection.value = 'prev';
    setTimeout(() => {
      router.push('/ihram');
    }, 300);
  }
};
</script>

<template>
  <section class="background" @touchstart="handleTouchStart" @touchend="handleTouchEnd">
  <div class="container">
      <div class="header_block">
        <router-link to="">
          <img src="@/assets/img/icons/prev.svg">
        </router-link>
        <h2 class="title">Ихрам</h2>
       <div></div>
      </div>
        <div class="content">
          <p class="text">Мужчине следует облачиться в две белые, чистые и не шитые материи, одну из которых обворачивают вокруг нижней части тела, а другую накидывают на плечи. Нижняя материя называется изар, а верхняя рида</p>

          <div class="people">
            <div class="container people__container">
              <img src="@/assets/img/adam1.png">
              <img src="@/assets/img/adam2.png">
              <img src="@/assets/img/adam3.png">
              <img src="@/assets/img/adam4.png">
            </div>
          </div>

          <div class="info">
            <div class="info__block">
              <img src="@/assets/img/icons/1.svg">
              <p class="text">Поставьте<br>
                ноги на<br>
                ширине<br>
                плеч и<br>
                затяните<br>
                края изара</p>
            </div>
            <div class="info__block">
              <img src="@/assets/img/icons/2.svg">
              <p class="text">Затяните<br>
                по туже<br>
                верхние<br>
                края изара<br>
                и скрутите<br>
                их к низу</p>
            </div>
            <div class="info__block">
              <img src="@/assets/img/icons/3.svg">
              <p class="text">Поправьте<br>
                изар таким,<br>
                образом<br>
                чтобы он<br>
                был выше<br>
                пупка и<br>
                ниже колен</p>
            </div>
            <div class="info__block">
              <img src="@/assets/img/icons/4.svg">
              <p class="text">Накиньте<br>
                рида на<br>
                левое<br>
                плечо...</p>
            </div>
          </div>
          <div class="pagination">
            <span >{{ 2 }}/{{ 8 }}</span>
          </div>
        </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.content {
  position: relative;
}
.people {
  background-color: #187E8D;
  padding-block: 40px;
  padding-inline: 20px;
  margin-block: 16px;
  &__container {
    display: flex;
    justify-content: space-between;
  }
}

.info {
  display: flex;
  justify-content: space-between;
  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;

    & img {
      padding-bottom: 10px;
    }
  }
}

.pagination {
  position: absolute;
  bottom: 27px;
  left: 50%;
  transform: translateX(-50%);
  color: #525D68;
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
