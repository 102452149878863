<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const touchStartX = ref(0);
const touchEndX = ref(0);
const swipeDirection = ref('');

const handleTouchStart = (event) => {
  touchStartX.value = event.changedTouches[0].clientX;
};

const handleTouchEnd = (event) => {
  touchEndX.value = event.changedTouches[0].clientX;
  const swipeThreshold = 50;

  if (touchStartX.value - touchEndX.value > swipeThreshold) {
    swipeDirection.value = 'next';
    setTimeout(() => {
      router.push('/fifthTawaf');
    }, 300);
  } else if (touchEndX.value - touchStartX.value > swipeThreshold) {
    swipeDirection.value = 'prev';
    setTimeout(() => {
      router.push('/thirdTawaf');
    }, 300);
  }
};
</script>

<template>
  <section class="background" @touchstart="handleTouchStart" @touchend="handleTouchEnd">
    <div class="container">
      <div class="header_block">
        <router-link to="">
          <img src="@/assets/img/icons/prev.svg">
        </router-link>
        <h2 class="title">Таваф</h2>
        <div></div>
      </div>

      <div class="content">
        <div class="info">
          <p class="text text_arab">رَبَّنَا آتِنَا فِي الدُّنْيَا حَسَنَةً وَفِي الآخِرَةِ حَسَنَةً وَقِنَا عَذَابَ النَّارِ</p>
          <p class="text text_center">«Раббана атина фид дуния хасана-тау уа филь
            ахирати хасанатау уа қинә ъазәбәннәр»</p>

          <p class="text text_orange text_center">«Господь наш! Одари нас добром в этом мире и добром в Последней жизни и защити нас от мучений в огне»</p>
        </div>
        <div class="info">
          <h2 class="title_pre">ПОСЛЕ ЗАВЕРШЕНИЯ ТАВАФА</h2>
          <p class="text">1. Необходимо закрыть правое плечо (мужчи- нам).</p>
          <p class="text">2. Совершить намаз в два рака’ата за <span class="text_orange">Макамом Ибрахима,</span> что является сунной. Если читать намаз за макамом затруд- нительно, то можно совершить его в любом другом месте мечети. В первом рака’ате после аль-фатихи желательно прочитать суру «аль-кяфирун». Во втором рака’ате после аль-фатихи желательно прочитать суру «аль-ихляс». Разрешается, если эти две суры будут заменены на другие.</p>
        </div>
        <div class="pagination">
          <span>{{ 4 }}/{{ 5 }}</span>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.content {
  position: relative;
}
.info {
  &:not(:last-child) {
    padding-bottom: 24px;
  }
  .text {
    &:not(:first-child) {
      padding-top: 16px;
    }
    &_center {
      text-align: center;
    }
  }
}
.pagination {
  position: absolute;
  bottom: 27px;
  left: 50%;
  transform: translateX(-50%);
  color: #525D68;
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
