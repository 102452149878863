<script setup>
import { ref } from 'vue';

const currentPage = ref(1);
const totalPages = ref(15);

let startX = 0;
let startY = 0;

function onTouchStart(event) {
  startX = event.touches[0].clientX;
  startY = event.touches[0].clientY;
}

function onTouchMove(event) {
  let deltaX = event.touches[0].clientX - startX;
  let deltaY = event.touches[0].clientY - startY;
  if (Math.abs(deltaY) < Math.abs(deltaX)) {
    event.preventDefault();
  }
}

function onTouchEnd(event) {
  let deltaX = event.changedTouches[0].clientX - startX;
  if (deltaX > 50) {
    goToPreviousPage();
  } else if (deltaX < -50) {
    goToNextPage();
  }
}

function goToPreviousPage() {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
}

function goToNextPage() {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
}
</script>

<template>
  <section class="dua" @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd">
    <div class="container dua__container">
      <div class="header-block">
        <router-link to="/dua">
          <img src="@/assets/img/icons/prev.svg">
        </router-link>
      </div>

      <div class="dua__content">
        <div class="dua__block">
          <div class="dua_box" v-if="currentPage === 1">
            <div class="scrollable-content">
              <p class="title_pre">ИЗБРАННЫЕ ДУА</p>
              <p class="text text_arab">لَا إِلَهَ إلَّا اللَّهُ وَحْدَهُ لَا شَرِيكَ لَهُ، لَهُ الْمُلْكُ وَلَهُ<br> الْحَمْدُ، وَهُوَ عَلَى كُلِّ شَيْءٍ قَدِيرٌِِ</p>
              <p class="text">«Лә иләһә иллал-лаһу уахдәһу лә шәрикә<br>
                ләһу ләһул-мулку уә ләһул хамду уә һууә<br>
                ъалә кулли шәй-ин қадир»</p>
              <p class="text text_orange">«Лучшим ду’а яв- ляется ду’а в день<br>
                Арафата, и лучшее из того, что говорил я и<br>
                пророки до меня: «нет божества, кроме<br>
                Аллаха, Он один, не имеет сотоварищей. Ему<br>
                принадлежит власть и вся хвала и Он над<br>
                всякой вещью мощен»</p>
            </div>
          </div>

          <div class="dua_box" v-if="currentPage === 2">
            <div class="scrollable-content">
              <p class="title_pre">ИЗБРАННЫЕ ДУА</p>
              <p class="text text_arab">سُبْحَانَ اللهِ، اَلْحَمْدُ للهِ، لَا إِلَهَ إِلاَّ اللهُ،<br>،اَللهُ أَكْبَرُ</p>
              <p class="text">«Субхән Аллаһ, әлхамду лилләһ,<br>
                уә лә иләһә иллаллаһу уаллаһу әкбәр»</p>
              <p class="text text_orange">«Свят Аллах, хвала Аллаху,<br>
                нет божества, кроме Аллаха,<br>
                и Аллах велик»</p>
            </div>
          </div>

          <div class="dua_box" v-if="currentPage === 3">
            <div class="scrollable-content">
              <p class="title_pre">ИЗБРАННЫЕ ДУА</p>
              <p class="text text_arab">،اللَّهُمَّ إِنِّي أَسْأَلُكَ الْعَفْوَ وَالْعَافِيَةَ فِي الدُّنْيَا وَالآخِرَةِ<br>
                اللَّهُمَّ إِنِّي أَسْأَلُكَ الْعَفْوَ وَالْعَافِيَةَ: فِي دِينِي وَدُنْيَايَ<br>
                ،وَأَهْلِي، وَمَالِي، اللَّهُمَّ اسْتُرْ عَوْرَاتِي، وَآمِنْ رَوْعَاتِي<br>
                اللَّهُمَّ احْفَظْنِي مِنْ بَينِ يَدَيَّ، وَمِنْ خَلْفِي، وَعَنْ<br>
                يَمِينِي، وَعَنْ شِمَالِي، وَمِنْ فَوْقِي، وَأَعُوذُ بِعَظَمَتِكَ <br>
                أَنْ أُغْتَالَ مِنْ تَحْتِيِ</p>
              <p class="text">«Аллахумма инни асалукаль-афуа<br>
                уаль-афия фи дини уа дунийая уа ахли уа<br>
                мали. Аллахумма-стур аурати уа амин<br>
                рауати. Аллахумма ихфиз-ни мин байни<br>
                йадайа уа мин халфи уа ан йамини уа ан<br>
                шимали уа мин фауки, ау ауъузу биъазама- <br>
                тика ан угталя мин тахти»</p>
              <p class="text text_orange text_bottom">«О Аллах! Поистине, я прошу тебя о проще-<br>
                нии и благополучии в религии, моих мирских<br>
                делах, в семье и в имуществе. о аллах,<br>
                прикрой мою наготу, и обезопась от страха.<br>
                о аллах, сохрани меня спереди и сзади,<br>
                справа и слева, сверху и прибегаю к твоему<br>
                величию от того, чтобы быть предательски<br>
                убитым снизу»</p>
            </div>
          </div>

          <div class="dua_box" v-if="currentPage === 4">
            <div class="scrollable-content">
              <p class="title_pre">ИЗБРАННЫЕ ДУА</p>
              <p class="text text_arab">اللهم إني اسألك الرضا بعد القضاء، واسألك برد<br>
                ،العيش بعد الموت، واسألك لذة النظر إلى وجهك<br>
                والشوق إلى لقائك، في غير ضراء مضرة، ولا فتنة<br>
                مضلةِ</p>
              <p class="text">«Аллахумма инни асалука ар-рида баъдаль-<br>
                када уа бардаль-ъиши баъдаль-маути уа<br>
                лазза-тан-назари иля уажхикаль-карими<br>
                уаш-шаука иля ликаика фи гайри дарраин<br>
                мударра уа ля фитнатин мудилля»</p>
              <p class="text text_orange text_bottom">«О Аллах! Я прошу довольствия после<br>
                предопределения и благой жизни после<br>
                смерти, наслаждения взглядом на Твой<br>
                прекрасный Лик и тоску по встрече с Тобой,<br>
                не будучи подвергнутым испытанию или<br>
                заблуждающей смуте».</p>
            </div>
          </div>

          <div class="dua_box" v-if="currentPage === 5">
            <div class="scrollable-content">
              <p class="title_pre">ИЗБРАННЫЕ ДУА</p>
              <p class="text text_arab">ِاللهمَّ آتِ نفسي تقواها، وزكِّهَا أنت خيرُ من زَكَّاهَا،<br>
                أنت وَلِيُّهَا ومولاها، اللهمَّ إني أعوذ بك من علمٍ لا<br>
                ،ينفع، ومن قلبٍ لا يخشع، ومن نَفْسٍ لا تشبع<br>
                ومن دعوةٍ لا يُستجاب لها</p>
              <p class="text">«Аллахумма ати нафси такуаха, уа заккиха<br>
                анта хайрун ман заккаха, анта уалиюха уа<br>
                мау-ляха. Аллахумма инии аъузу бика мин<br>
                ъилмин ля йанфауъ уа калбин ля йахшаъу уа<br>
                даъуатин ля йустажабу ляха»</p>
              <p class="text text_orange text_bottom">«О Аллах! Дай моей душе богобоязненность,<br>
                очисти ее и ты лучший, кто может это сделать.<br>
                ты ее Покровитель и господин. о аллах, я<br>
                прибегаю к тебе от бесполезного знания, от<br>
                сердца не страшащегося, от ненасытной<br>
                души и мольбы без ответ»</p>
            </div>
          </div>

          <div class="dua_box" v-if="currentPage === 6">
            <div class="scrollable-content">
              <p class="title_pre">ИЗБРАННЫЕ ДУА</p>
              <p class="text text_arab">،اللَّهُمَّ عَافِنِي فِي بَدَنِي، اللَّهُمَّ عَافِنِي فِي سَمْعِي<br>
                اللَّهُمَّ عَافِنِي فِي بَصَرِي، لَا إِلَهَ إلَّا أَنْتَ. اللَّهُمَّ إِنِّي<br>
                أَعُوذُ بِكَ مِنَ الْكُفْرِ، وَالفَقْرِ، وَأَعُوذُ بِكَ مِنْ عَذَابِ<br>
                القَبْرِ، لَا إِلَهَ إلَّا أَنْتَِ</p>
              <p class="text">Аллахумма ‘афи-ни фи бадани Аллахумма<br>
                ‘афи-ни фи сам‘и Аллахумма ‘афи-ни фи<br>
                басари; ля иляха илля анта! Аллахумма ин-<br>
                ни а‘узу би-ка мин аль-куфри ва-ль-факри ва<br>
                а‘узу би-ка мин ‘азаби ль-кабри ля иляха<br>
                илля анта!</p>
              <p class="text text_orange text_bottom">О Аллах, убереги моё тело, о Аллах,<br>
                убереги  мой слух, о Аллах, убереги моё<br>
                зрение — нет бога, кроме Тебя! О Аллах,<br>
                поистине, я прибегаю к Твоей защите от<br>
                неверия и бедности и я прибегаю к Твоей<br>
                защите от мук могилы — нет бога, кроме Тебя!</p>
            </div>
          </div>

          <div class="dua_box" v-if="currentPage === 7">
            <div class="scrollable-content">
              <p class="title_pre">ИЗБРАННЫЕ ДУА</p>
              <p class="text text_arab">اللَّهُمَّ إِنِّي أَعُوذُ بِكَ مِنْ شَرِّ مَا عَمِلْتُ، وَمِنْ <br>
                شَرِّ مَا لَمْ أَعْمَلْ.  اللَّهُمَّ إِنِّي أَعُوذُ بِكَ مِنْ <br>
                زَوَالِ نِعْمَتِكَ، وَتَحَوُّلِ عَافِيَتِكَ، وَفُجَاءَةِ <br>
                نِقْمَتِكَ، وَجَمِيعِ سَخَطِكَ</p>
              <p class="text">«Аллахумма инни аъуузу бика мин шарри ма<br>
                амильту, уа мин шарри ма лям аъмаль, Алла-<br>
                хумма инни аъузу бика мин зауали ниъматика<br>
                уа тахауули афиятика, уа фаж’ати никмати-<br>
                ка, уа жамиъи сахатика»</p>
              <p class="text text_orange text_bottom">«О Аллах! Я прибегаю к тебе от зла,<br>
                сотворенного мною, и от зла, не<br>
                сотворенного мною. О Аллах, я прибегаю к<br>
                Тебе от прекращения Твоей милости, от<br>
                перемены благополучия, полученного от<br>
                Тебя, от внезапного бедствия и от всего, что<br>
                вызовет Твой гнев»</p>
            </div>
          </div>

          <div class="dua_box" v-if="currentPage === 8">
            <div class="scrollable-content">
              <p class="title_pre">ИЗБРАННЫЕ ДУА</p>
              <p class="text text_arab">ِاللهُمَّ أَصْلِحْ لِي دِينِي ، وَوَسِّعْ لي فِي داري ، وَبَارِكْ <br>
                ،لِي فِي رِزْقِي. اللَّهُمَّ إنِّي أعُوذُ بِكَ مِنَ العَجْزِ <br>
                ،والكَسَلِ، والجُبْنِ، والبُخْلِ، والهَرَمِ، والقَسْوَةِ <br>
                والغَفْلَةِ، والعَيْلَةِ، والذِّلَّةِ، والمَسْكَنَةِ، وأعُوذُ بِكَ مِنَ <br>
                ،الفَقْرِ، والكُفْرِ، والشِّرْك، والفُسُوقِ، والشِّقاقِ <br>
                ،والنِّفاقِ، والسُّمْعَةِ، والرِّياءِ، وأعُوذُ بِكَ مِنَ الصَّمَمِ <br>
                والبَكَمِ، والجُنُونِ، والجُذامِ، والبَرَصِ، وَسَيِّىءِ <br>
                الأَسْقامِِ</p>
              <p class="text">«Аллахумма аслих ли дини, уа уасиъ ли дари,<br>
                уа барик ли фи ризки, Аллахумма инни аъузу<br>
                бика миналь-касуа уаль-гафля, уаз-зилля<br>
                уаль-маскана, уа аъузу бика миналь-куфри<br>
                уаль-фу-суки, уаш-шикаки, уас-сумъати,<br>
                уар-риаи, уа аъузу бика минас-самами,<br>
                уаль-букми, уаль-джизами уа сиаль-асками»</p>
              <p class="text text_orange text_bottom">«О Аллах! Приведи в порядок мою религию,<br>
                расширь мое жилище и благослови меня в<br>
                моем уделе. О Аллах, я прибегаю к Тебе от<br>
                черствости, небрежности, униженности и<br>
                бедности. И прибегаю к Тебе от неверия,<br>
                порочности, раздора, стремления<br>
                к похвалам людей и от показухи.<br>
                И прибегаю к Тебе от глухоты,<br>
                немоты, проказы и от плохих болезней»</p>
            </div>
          </div>

          <div class="dua_box" v-if="currentPage === 9">
            <div class="scrollable-content">
              <p class="title_pre">ИЗБРАННЫЕ ДУА</p>
              <p class="text text_arab"> ، رب اجعلني لك شكارا لك ، ذكارا لك ، رهابا لك<br>
                مطواعا لك ، مخبتا إليك ، أواها منيبا ، تقبل <br>
                توبتي ، وأجب دعوتي ، واهد قلبي ، وثبت حجتي ، <br>
                وسدد لساني ، واسلل سخيمة قلبيِِ</p>
              <p class="text">«Аллахумма иджальни заккаран ляка, шакка-<br>
                ран ляка, мутаууаъан ляка, мухбитан илейка,<br>
                аууахан мунибан. Рабби такаббаль таубати,<br>
                уагсиль хубати, уа ажиб даъуати, уа саббит<br>
                хужжати, уахди калбьи, уа саддид лисани<br>
                уас-луль сахимата садри»</p>
              <p class="text text_orange text_bottom">«О Аллах! Сделай меня часто поминающим<br>
                Тебя, благодарным Тебе, покорным Тебе,<br>
                кающимся перед Тобой. О Господь, прими<br>
                мое покаяние, очисти от грехов, ответь на<br>
                мою молитву, сделай мои доводы<br>
                убедительными, наставь мое сердце на<br>
                истинный путь, исправь мой язык и потуши<br>
                ненависть в моей груди»</p>
            </div>
          </div>

          <div class="dua_box" v-if="currentPage === 10">
            <div class="scrollable-content">
              <p class="title_pre">ИЗБРАННЫЕ ДУА</p>
              <p class="text text_arab">اللَّهمَّ إنِّي أعوذُ بكَ منَ الهدْمِ وأعوذُ بكَ منَ التَّردِّي<br>
                وأعوذُ بكَ منَ الغرَقِ والحرْقِ والهرَمِ وأعوذُ بكَ أن<br>
                يتخبَّطني الشَّيطانُ عندَ الموتِ وأعوذُ بكَ أن<br>
                أموتَ في سبيلِكَ مدبرًا وأعوذُ بكَ أن أموتَ لديغًاِ</p>
              <p class="text">«Аллахумма инни аъуузу бика<br>
                миналь-хадами уат-тарадди уа миналь-<br>
                гарки, уаль-харри, уаль- харами. Уа аъуузу<br>
                бика ан йатахаббатаниш- шайтану иъндаль-<br>
                маути. Уа аъуузу бика мин ан амута лядиган.<br>
                Уа аъуузу бика мин тамъин йахди иля табъин»</p>
              <p class="text text_orange text_bottom">«О Аллах! Я прибегаю к Тебе от того,<br>
                чтобы оказаться под завалом или разбиться<br>
                с высоты. (Прибегаю) от утопления, от<br>
                смерти в огне, старческой дряхлости.<br>
                И прибегаю к Тебе от того, чтобы шайтан<br>
                смог при смерти найти путь ко мне.<br>
                Я прибегаю к Тебе от смерти, вызванной<br>
                укусом ядовитого животного или<br>
                насекомого. И прибегаю к Тебе от желаний,<br>
                переходящих в мое естество»</p>
            </div>
          </div>

          <div class="dua_box" v-if="currentPage === 11">
            <div class="scrollable-content">
              <p class="title_pre">ИЗБРАННЫЕ ДУА</p>
              <p class="text text_arab">،اللَّهمَّ إِنِّي أَعُوذُ بِكَ مِن منْكَرَاتِ الأَخلاقِ<br>
                والأعْمَالِ والأَهْواءِ. اللَّهُمَّ إنِّي أَعُوذُ بِكَ مِنْ غَلَبَةِ<br>
                الدَّيْنِ، وَقَهْرِ الرِّجَالِ، وَشَمَاتَةِ الأعْدَاءِِِ</p>
              <p class="text">«Аллахумма инни аъуузу бика мин<br>
                мункара-тиль-ахляки уаль-аъмали<br>
                уаль-ахуаи уаль-аду-аи, уа аъузу бика мин<br>
                галябатид-дайни ув ках-рир-риджали уа<br>
                шамататил-аъдаи»</p>
              <p class="text text_orange text_bottom">«О Аллах! Я прибегаю к ебе от порицаемых<br>
                нравственных качеств, дел, устремлений и<br>
                болезней. И прибегаю к Тебе от бремени<br>
                долга, принуждения людей и злорадства<br>
                врагов»</p>
            </div>
          </div>

          <div class="dua_box" v-if="currentPage === 12">
            <div class="scrollable-content">
              <p class="title_pre">ИЗБРАННЫЕ ДУА</p>
              <p class="text text_arab">،اللهم أصلح لي ديني الذي هو عصمة أمري<br>
                وأصلح لي دنياي التي فيها معاشي، وأصلح لي<br>
                آخرتي التي فيها معادي، واجعل الحياة زيادة لي<br>
                .من كل خير، واجعل الموت راحة لي من كل شر<br>
                ،رَبِّ أَعِنِّي وَلَا تُعِنْ عَلَيَّ، وَانْصُرْنِي وَلَا تَنْصُرْ عَلَيَّ<br>
                وَامْكُرْ لِي وَلَا تَمْكُرْ عَلَيَّ، وَاهْدِنِي وَيَسِّرِ الهُدَى إِلَيَِِّ</p>
              <p class="text">«Аллахумма аслих ли дини аллязи хуа<br>
                ъисмату амри, уа аслих ли дунийая алляти<br>
                фиха маъа- ши, уа аслих ли ахирати алляти<br>
                иляйха маъади, уаджаълиль-хайата зиядатан<br>
                ли фи кулли хайр, уаджалиль-маута рахатан<br>
                ли мин кулли шарр. Уа рабби аъинни уа ля<br>
                туъйин алеййя, уансурни уа ля тансур<br>
                алеййя, уахдини уа йассириль-худа ли»</p>
              <p class="text text_orange text_bottom">«О Аллах! Приведи в порядок мою религию, в<br>
                которой моя защита, и приведи в порядок<br>
                этот мир, в котором я живу, и приведи в<br>
                порядок мою следующую жизнь, к которой я<br>
                вернусь. и сделай так, чтобы жизнь лишь<br>
                добавляла мне всякое благо, и сделай<br>
                смерть отдохновением от всякого зла. О<br>
                Аллах, поддержи меня и не помогай против<br>
                меня, даруй победу мне и пусть не одержат<br>
                ее надо мной, выведи меня на прямой путь и<br>
                облегчи мне его»</p>
            </div>
          </div>

          <div class="dua_box" v-if="currentPage === 13">
            <div class="scrollable-content">
              <p class="title_pre">ИЗБРАННЫЕ ДУА</p>
              <p class="text text_arab">ِاللَّهُمَّ إِنِّي أَسْأَلُكَ صِحَّةً فِي إِيمَانٍ ، وَإِيمَانًا فِي<br>
                ،حُسْنِ خُلُقٍ ، وَنَجَاحًا يَتْبَعُهُ فَلَاحٌ ، وَرَحْمَةً مِنْكَ <br>
                وَعَافِيَةً ، وَمَغْفِرَةً مِنْكَ ، وَرِضْوَانًاِ</p>
              <p class="text">«Аллахумма инни асалука сиххатан фи имани<br>
                уа иманан фи хусни хулуки, уа нажахан<br>
                йатба-ъуху фаляху, рахматан минка уа<br>
                афиятан мин-ка, уа магфиратан, уа<br>
                ридуанан»</p>
              <p class="text text_orange text_bottom">«О Аллах! Я прошу у Тебя здравой веры, и<br>
                веры в благих нравственных качествах, и ви-<br>
                димый успех, за которым последует истинный<br>
                (успех), и Твоей милости, и благополучия от<br>
                Тебя, прощения и довольствия».</p>
            </div>
          </div>

          <div class="dua_box" v-if="currentPage === 14">
            <div class="scrollable-content">
              <p class="title_pre">ИЗБРАННЫЕ ДУА</p>
              <p class="text text_arab">،اللَّهُمَّ عَافِنِي فِي بَدَنِي، اللَّهُمَّ عَافِنِي فِي سَمْعِي<br>
                اللَّهُمَّ عَافِنِي فِي بَصَرِي، لَا إِلَهَ إلَّا أَنْتَ. اللَّهُمَّ إِنِّي<br>
                أَعُوذُ بِكَ مِنَ الْكُفْرِ، وَالفَقْرِ، وَأَعُوذُ بِكَ مِنْ عَذَابِ<br>
                القَبْرِ، لَا إِلَهَ إلَّا أَنْتَِ</p>
              <p class="text">Аллахумма ‘афи-ни фи бадани Аллахумма <br>
                ‘афи-ни фи сам‘и Аллахумма ‘афи-ни фи <br>
                басари; ля иляха илля анта! Аллахумма ин-<br>
                ни а‘узу би-ка мин аль-куфри ва-ль-факри<br>
                ва а‘узу би-ка мин ‘азаби ль-кабри ля иляха<br>
                илля анта!</p>
              <p class="text text_orange text_bottom">О Аллах, убереги моё тело, о Аллах, убереги<br>
                мой слух, о Аллах, убереги моё зрение - нет<br>
                бога, кроме Тебя! О Аллах, поистине, я <br>
                прибегаю к Твоей защите от неверия и <br>
                бедности и я прибегаю к Твоей защите<br>
                от мук могилы - нет бога, кроме Тебя!</p>
            </div>
          </div>

          <div class="dua_box" v-if="currentPage === 15">
            <div class="scrollable-content">
              <p class="title_pre">ИЗБРАННЫЕ ДУА</p>
              <p class="text text_arab">،اللَّهُمَّ أَنْتَ رَبِّي لَا إِلَهَ إلَّا أَنْتَ، خَلَقْتَنِي وَأَنَا عَبْدُكَ،<br>
                وَأَنَا عَلَى عَهْدِكَ وَوَعْدِكَ مَا اسْتَطَعْتُ، أَعُوذُ بِكَ<br>
                مِنْ شَرِّ مَا صَنَعْتُ، أَبُوءُ لَكَ بِنِعْمَتِكَ عَلَيَّ، وَأَبُوءُ <br>
                بِذَنْبِي فَاغْفِرْ لِي فَإِنَّهُ لَا يَغْفِرُ الذُّنوبَ إلَّا أَنْتَ</p>
              <p class="text">Аллахумма анта Рабби ля иляха илля анта;<br>
                халякта-ни ва ана ‘абду-ка ва ана ‘аля <br>
                ‘ахди-ка ва ва‘ди-ка ма-стата‘ту. А‘узу би-ка<br>
                мин шарри ма сана‘ту абу’у ля-ка би-<br>
                ни‘мати-ка ‘аляййа ва абу’у би-занби фа-<br>
                гфир ли фа-инна-ху ля йагфиру з-зунуба<br>
                илля анта!</p>
              <p class="text text_orange text_bottom">О Аллах! Ты — Господь мой, и нет бога, кроме<br>
                Тебя; Ты создал меня, а я — Твой раб, и я буду<br>
                верен договору, с Тобой, и обещанию, Тебе,<br>
                пока у меня хватит сил. Прибегаю к Твоей<br>
                защите от зла того, что я сделал, признаю<br>
                милость, оказанную Тобой мне, и признаю <br>
                грех свой, прости же меня, ибо, поистине,<br>
                никто не простит грехов, кроме Тебя.</p>
            </div>
          </div>

          <span class="pagination">{{ currentPage }}/{{ totalPages }}</span>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.dua {
  &__container {
    height: 100%;
    flex: 1;
    overflow: hidden;
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  &__block {
    position: relative;
    background-color: white;
    border-radius: 20px;
    padding: 35px 0;
    text-align: center;
    min-height: 400px;
    width: 100%;
    overflow: auto;
    display: inline-block;
    //height: calc(100% - 70px);
    overflow: hidden;
  }
  .text {
    text-align: center;
    padding-top: 24px;
    &-top {
      padding-top: 24px;
    }
    &_bottom {
      padding-bottom: 35px;
    }
  }
  .scrollable-content {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 20px;
  }
  .texts {
    color: #525D68;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    text-align: justify;
    text-align: center;
    padding-top: 16px;
  }
  .pagination {
    position: absolute;
    bottom: 0;
    padding-bottom: 30px;
    transform: translateX(-50%);
    color: #525D68;
  }
}
</style>
