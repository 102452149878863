<script setup>
import { ref, onMounted } from 'vue';

const requestTime = ref('');

const currencies = [
  { code: "USD", buy: "435,7", sell: "445,7" },
  { code: "EUR", buy: "435,7", sell: "445,7" },
  { code: "ОАЭ", buy: "435,7", sell: "445,7" },
  { code: "RUB", buy: "435,7", sell: "445,7" },
  { code: "CHY", buy: "435,7", sell: "445,7" },
  { code: "KGS", buy: "435,7", sell: "445,7" },
  { code: "GOLD", buy: "435,7", sell: "445,7" },
  { code: "CHF", buy: "435,7", sell: "445,7" },
]

const formatDate = (date) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
  return new Date(date).toLocaleString('ru-RU', options);
};

onMounted(() => {
  setTimeout(() => {
    requestTime.value = formatDate(new Date());
  }, 1000);
});
</script>

<template>
  <section class="exchanger">
    <div class="container exchanger__container">
      <div class="content">
        <p class="text">На {{ requestTime }}</p>
        <div class="table">
          <div class="table__header">
            <p class="text">Покупка</p>
            <span></span>
            <p class="text">Продажа</p>
          </div>
          <div class="table__row" v-for="currency in currencies" :key="currency.code">
            <div class="cell bordered"><p class="text_number">{{ currency.buy }}</p></div>
            <div class="cell currency-code"><p class="text_currency">{{ currency.code }}</p></div>
            <div class="cell bordered"><p class="text_number">{{ currency.sell }}</p></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.exchanger {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
}

.content {
  background-color: #034752;
  padding: 20px;
  border-radius: 15px;
  max-width: 400px;
  width: 100%;
}

.text {
  color: #FFFFFF;
  text-align: center;
  &_number {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
  }
  &_currency {
    font-size: 20px;
    font-weight: 700;
    line-height: 18px;
  }
}

.table {
  display: flex;
  flex-direction: column;
  gap: 14px;

  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    font-weight: bold;
    padding-top: 19px;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #034752;
    border-radius: 8px;
  }
  &__row:not(:last-child) {
    margin-bottom: 13px;
  }
}

.cell {
  color: #FFFFFF;
  text-align: center;
  flex: 1;
  font-size: 16px;

  &.bordered {
    backdrop-filter: blur(4px);
    background-color: rgba(255, 255, 255, 0.1);
    border: 0.5px solid rgba(255, 255, 255, 0.5);
    padding: 14px 0;
    border-radius: 8px;
  }
}

.currency-code {
  font-weight: bold;
  text-transform: uppercase;
}
</style>
