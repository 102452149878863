<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const touchStartX = ref(0);
const touchEndX = ref(0);
const swipeDirection = ref('');

const handleTouchStart = (event) => {
  touchStartX.value = event.changedTouches[0].clientX;
};

const handleTouchEnd = (event) => {
  touchEndX.value = event.changedTouches[0].clientX;
  const swipeThreshold = 50;

  if (touchStartX.value - touchEndX.value > swipeThreshold) {
    swipeDirection.value = 'next';
    setTimeout(() => {
      router.push('/fourthTawaf');
    }, 300);
  } else if (touchEndX.value - touchStartX.value > swipeThreshold) {
    swipeDirection.value = 'prev';
    setTimeout(() => {
      router.push('/secondTawaf');
    }, 300);
  }
};
</script>

<template>
  <section class="background" @touchstart="handleTouchStart" @touchend="handleTouchEnd">
    <div class="container">
      <div class="header_block">
        <router-link to="">
          <img src="@/assets/img/icons/prev.svg">
        </router-link>
        <h2 class="title">Таваф</h2>
        <div></div>
      </div>

        <div class="content">
          <div class="info">
            <p class="text text_arab">بِسْمِ اللَّهِ، وَالصَّلَاةُ وَالسَّلَامُ عَلَى رَسُولِ اللَّهِ اللَّهُمَّ <br>افْتَحْ لِي أَبْوَابَ رَحْمَتِكََ</p>
            <p class="text center">«Би-сми Лляхи ва-с-саляту ва-с-саляму ‘аля расули Ллях. Аллахумма фтах ли абваба рахмати-ка»</p>
            <p class="text text_orange text_center">«С именем Аллаха, благословение и мир посланнику Аллаха. О Аллах! Открой для меня врата Твоего милосердия»</p>

            <p class="text">Затем паломник направляется к Каабе и начинает таваф. Для мужчин, совершающих умру или таваф аль-кудум, сунной является обнажать правое плечо. Для этого середина накидки рида ложится под правой подмы- шечной впадиной, а края закидываются на левое плечо.</p>
            <div class="info_left">
              <p class="text">1. Далее совершающий умру приступает к семикратному обходу вокруг каабы. Первый круг начинается с черного камня со словами: <span class="text_orange">«Бисмилляхи, Аллаху акбар».</span>  Достаточно издали указать правой рукой на черный камень со словами <span class="text_orange">«Аллаху акбар».</span></p>
              <p class="text">2. В промежутке между йеменским углом и черным камнем в каждом кругу по сунне следует читать:</p>
            </div>
          </div>
          <div class="pagination">
            <span >{{ 3 }}/{{ 5 }}</span>
          </div>
        </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.content {
  position: relative;
}
.info {
  &_left {
   margin-left: 10px;
    padding-top: 20px;
  }
  .text {
    &:not(:first-child) {
      padding-top: 25px;
    }
    &_center {
      text-align: center;
    }
  }
}
.pagination {
  position: absolute;
  bottom: 27px;
  left: 50%;
  transform: translateX(-50%);
  color: #525D68;
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
