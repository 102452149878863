<script setup>
import { ref } from 'vue';

const currentPage = ref(1);
const totalPages = ref(3);

let startX = 0;
let startY = 0;

function onTouchStart(event) {
  startX = event.touches[0].clientX;
  startY = event.touches[0].clientY;
}

function onTouchMove(event) {
  let deltaX = event.touches[0].clientX - startX;
  let deltaY = event.touches[0].clientY - startY;
  if (Math.abs(deltaY) < Math.abs(deltaX)) {
    event.preventDefault();
  }
}

function onTouchEnd(event) {
  let deltaX = event.changedTouches[0].clientX - startX;
  if (deltaX > 50) {
    goToPreviousPage();
  } else if (deltaX < -50) {
    goToNextPage();
  }
}

function goToPreviousPage() {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
}

function goToNextPage() {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
}
</script>


<template>
  <section class="dua" @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd">
    <div class="container dua__container">
      <div class="header-block">
        <router-link to="/dua">
          <img src="@/assets/img/icons/prev.svg">
        </router-link>
      </div>

     <div class="dua__content">
       <div class="dua__block">
         <div class="dua_box" v-if="currentPage === 1">
           <p class="title_pre">ТАВАФ</p>
           <p class="text text_arab">أَعُوذُ بِاللَّهِ العَظِيمِ، وَبِوَجْهِهِ الْكَرِيمِ، وَسُلْطَانِهِ<br> الْقَدِيمِ، مِنَ الشَّيْطَانِ الرَّجِيمِ</p>
           <p class="text">«А‘узу би-Лляхи ль-‘Азыми ва би-ваджхи-хи<br> ль-карими ва сультани-хи ль-кадими мин<br> аш-шайтани р-раджим»</p>
           <p class="text text_orange"> «Прибегаю к защите Аллаха Великого,<br>
             Его благородного лика и Его предвечной<br> власти от проклятого шайтана»</p>
         </div>

         <div class="dua_box" v-if="currentPage === 2">
           <p class="title_pre">ТАВАФ</p>
           <p class="text text_arab">بِسْمِ اللَّهِ، وَالصَّلَاةُ وَالسَّلَامُ عَلَى رَسُولِ اللَّهِ <br>اللَّهُمَّ افْتَحْ لِي أَبْوَابَ رَحْمَتِكَ</p>
           <p class="text">«Би-сми Лляхи ва-с-саляту ва-с-<br>саляму ‘аля расули Ллях. Аллахумма <br> фтах ли абваба рахмати-ка»</p>
           <p class="text text_orange"> «С именем Аллаха, благословение и <br> мир посланнику Аллаха. О Аллах! <br>Открой для меня врата Твоего<br> милосердия»</p>
         </div>

         <div class="dua_box" v-if="currentPage === 3">
           <p class="title_pre">СУРА «АЛЬ-КАФИРУН»</p>
           <p class="texts">Бисмилләһир-рахмәәнир-рахиим</p>
           <p class="texts">Қул йаә әйюһәл кәәфируун (1) Ләәә әъбуду<br> мәә тәъбудун (2) Уә ләә әнтум ъабидуунә мәә<br> әъбуд (3) Уә ләә әнә ъаабидум мәә ъабәдтум<br> (4) Уә ләә әнтум ъаабидуунә мәә әъбуд (5)<br> Ләкум диинукум уә лиә диин (6)</p>
           <p class="title_pre text-top">СУРА «АЛЬ-ИХЛАС»</p>
           <p class="texts">Бисмилләһир-рахмәәнир-рахиим</p>
           <p class="texts">Қул һуаллааһу ахаді (1) Аллаһус-сомәді (2)<br> Ләм йәлид уә ләм йууләді (3), уә ләм йәкул-<br>ләһуу куфууән ахаді (4)</p>
         </div>

         <span class="pagination">{{ currentPage }}/{{ totalPages }}</span>
       </div>
     </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.dua {
  &__container {
    height: 100%;
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  &__block {
    position: relative;
    background-color: white;
    border-radius: 20px;
    padding: 60px 0 30px 0;
    text-align: center;
    height: 355px;
    width: 100%;
  }
  .text {
    text-align: center;
    padding-top: 24px;
    &-top {
      padding-top: 24px;
    }
  }
  .texts {
    color: #525D68;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    text-align: justify;
    text-align: center;
    padding-top: 16px;
  }
  .pagination {
    position: absolute;
    bottom: 0;
    padding-bottom: 30px;
    transform: translateX(-50%);
    color: #525D68;
  }
}
</style>
