<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const touchStartX = ref(0);
const touchEndX = ref(0);
const swipeDirection = ref('');

const handleTouchStart = (event) => {
  touchStartX.value = event.changedTouches[0].clientX;
};

const handleTouchEnd = (event) => {
  touchEndX.value = event.changedTouches[0].clientX;
  const swipeThreshold = 50;

  if (touchStartX.value - touchEndX.value > swipeThreshold) {
    swipeDirection.value = 'next';
    setTimeout(() => {
      router.push('/thirdMikat');
    }, 300);
  } else if (touchEndX.value - touchStartX.value > swipeThreshold) {
    swipeDirection.value = 'prev';
    setTimeout(() => {
      router.push('/mikat');
    }, 300);
  }
};
</script>

<template>
  <section class="background" @touchstart="handleTouchStart" @touchend="handleTouchEnd">
    <div class="container">
      <div class="header_block">
        <router-link to="">
          <img src="@/assets/img/icons/prev.svg">
        </router-link>
        <h2 class="title">Микаты</h2>
        <div></div>
      </div>
        <div class="content">
          <div class="info">
            <p class="text"><span class="text__selected">3. Кари аль-маназиль قرن المنازل</span> - микат жителей Неджа
              (центральные и восточные районы Саудовской Аравии)
              и паломников, прибывающих с этого направления.
              Сайчас это место называется «ас-Сейлуль кабир» и
              находится в 75 км от Благословенной Мекки.</p>
            <p class="text"><span class="text__selected">4. Ялямлям يلملم</span> – микат жителей Йемена и паломников,
              прибывающих с этого направ- ления. Сейчас это место
              называется «ас-Саъдия» и находится в 92 км от
              Благосло- венной Мекки.</p>
            <p class="text"><span class="text__selected">5. Зату ъирк ذات عرق</span> – микат жителей Ирака и
              паломников, прибывающих с этого направ- ления. Он
              расположен в 94 км от Благосло- венной Мекки.</p>
          </div>

          <div class="steps">
            <div class="container">
              <i class="text_green">
                В хадисе передают, что пророк определил местом
                вступления в ихрам для жителей медины Зуль-Хулейфу,
                для приезжающих из Сирии – аль-Джухфу, для
                прибывающих из неджда – Карн аль- Маназиль, а для
                жителей йемена – Ялям- лям. Эти места предназначены
                для них, а также для всех, кто желает совершить хадж
                или малое паломничество, но прибывает к
                вышепе- речисленным местам не из упомянутых
                областей. а что касается тех, кто прожи- вает вблизи
                мекки, то их паломничество начнется там, откуда они
                отправятся в путь, и поэтому жители мекки должны
                вступать в ихрам в самой Мекке.
              </i>
              <p class="text__source">(Аль-Бухари и Муслим)</p>
            </div>
          </div>

          <div class="pagination">
            <span >{{ 2 }}/{{ 3 }}</span>
          </div>
        </div>
    </div>
  </section>
</template>


<style lang="scss" scoped>
.content {
  position: relative;
}
.info .text:nth-child(1),
.info .text:nth-child(2) {
  padding-bottom: 16px;
}
.steps {
  padding-block: 16px;
}
.pagination {
  position: absolute;
  bottom: 27px;
  left: 50%;
  transform: translateX(-50%);
  color: #525D68;
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
